@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}
.inputWithImage {
  position: relative;
}

.text {
  /* Position */
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translate(-0px, -50%);
  z-index: 9;
  color: #8e8e8e;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.305px;
}
.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  margin-top: 42px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
  text-align: center;
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.2px;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
  color: var(--marketplaceInitialDefaultColor);
  font-weight: 600;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
  color: var(--matterColor);
  font-weight: 500;
}
