@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}
.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: var(--matterColorBright);
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  cursor: pointer;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;

  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}
.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}
.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}
.uploadAvatarWrapper {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
    margin-bottom: 16px;
  }
}
/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}
.uploadAvatarInput {
  display: none;
}

.inputWithImage {
  position: relative;
}

.text {
  /* Position */
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translate(-0px, -50%);
  z-index: 9;
  color: #8e8e8e;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.305px;
}

.firstNameRoot {
  width: calc(50% - 10px);
}

.lastNameRoot {
  width: calc(50% - 10px);
}

.emailInput {
  @media (max-width: 767px) {
    flex-direction: column;
    & .firstNameRoot {
      width: calc(100% - 0px);
    }
    & .lastNameRoot {
      margin-top: 24px;
      width: calc(100% - 0px);
    }
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 32px;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
  margin-top: 0;
  margin-bottom: 21px;
}

.termsText {
  composes: marketplaceModalHelperText from global;
  color: var(--matterColor);
  text-align: center;
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.2px;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;
  color: var(--marketplaceInitialDefaultColor);
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.2px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.photoUploadWrapper {
  width: 124px;
  height: 124px;
  background: #e7e7e7;
  border-radius: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 26px;
  border: 3px solid #e7e7e7;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
}

.uploadImageWrapper {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 4;
}

.addPhotoLabel {
  position: absolute;
  color: #000;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.errorMessage {
  color: red;
}
